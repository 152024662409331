import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { IResponseSuccess } from '../../core/models/response-sucess.model';
import { ErrorHandlerService } from '../../core/services/error-handler.service';
import { ApiPartnersService } from '../../services';

import * as fromActions from './actions';
import { effectHooks } from '../../helpers';

@Injectable()
export class ApiPartnersStoreEffects {
  constructor(
    private dataService: ApiPartnersService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private router: Router,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(({ searchParams }) =>
        this.dataService.load(searchParams).pipe(
          map((response: IResponseSuccess) =>
            fromActions.loadSuccess({
              apiPartners: response.data,
              pagination: response.meta.pagination,
            }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFailure(error));
          }),
        ),
      ),
    ),
  );

  loadDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadDetailsRequest),
      switchMap(({ apiPartnerId }) =>
        this.dataService.loadDetails(apiPartnerId).pipe(
          map((response: IResponseSuccess) =>
            fromActions.loadDetailsSuccess({
              apiPartner: response.data,
              apiPartnerId,
            }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadDetailsFailure(error));
          }),
        ),
      ),
    ),
  );

  createApiPartner$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.createApiPartnerRequest),
      switchMap(({ apiPartner, hooks }) =>
        this.dataService.createApiPartner(apiPartner).pipe(
          effectHooks(hooks),
          map((apiPartner) => {
            this.router.navigate(['admin', 'api-partners']);

            return fromActions.createApiPartnerSuccess({
              apiPartner: apiPartner.data,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.createApiPartnerFailure(error));
          }),
        ),
      ),
    ),
  );

  editApiPartner$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.editApiPartnerRequest),
      switchMap(({ apiPartner }) =>
        this.dataService.editApiPartner(apiPartner).pipe(
          map((apiPartner) => {
            this.router.navigate(['admin', 'api-partners']);
            return fromActions.editApiPartnerSuccess({
              apiPartner: apiPartner.data,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.editApiPartnerFailure(error));
          }),
        ),
      ),
    ),
  );

  loadTags$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadTagsRequest),
      switchMap(() =>
        this.dataService.loadTags().pipe(
          map((response: IResponseSuccess) =>
            fromActions.loadTagsSuccess({
              tags: response.data,
            }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadTagsFailure(error));
          }),
        ),
      ),
    ),
  );

  loadContactTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadContactTypesRequest),
      switchMap(() =>
        this.dataService.loadContactTypes().pipe(
          map((response) =>
            fromActions.loadContactTypesSuccess({
              contactTypes: response.data,
            }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadContactTypesFailure(error));
          }),
        ),
      ),
    ),
  );

  loadServices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadServicesRequest),
      switchMap(() =>
        this.dataService.loadServices().pipe(
          map((response) =>
            fromActions.loadServicesSuccess({
              services: response.data,
            }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadServicesFailure(error));
          }),
        ),
      ),
    ),
  );

  deleteApiPartner$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteApiPartnerRequest),
      switchMap(({ apiPartnerId }) =>
        this.dataService.deleteApiPartner(apiPartnerId).pipe(
          map(() =>
            fromActions.deleteApiPartnerSuccess({
              apiPartnerId,
            }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.deleteApiPartnerFailure(error));
          }),
        ),
      ),
    ),
  );
}
