import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { IPagination } from '../../core/models/api/pagination/pagination.model';
import {
  ApiPartner,
  ApiPartnerContactType,
  ApiPartnerService,
  ApiPartnerTag,
} from '../../models';

export const featureAdapter: EntityAdapter<ApiPartner> =
  createEntityAdapter<ApiPartner>({
    selectId: (model) => model.id,
  });

export interface State extends EntityState<ApiPartner> {
  pagination: IPagination;
  isLoading?: boolean;
  error?: any;
  tags: ApiPartnerTag[];
  services: ApiPartnerService[];
  contactTypes: ApiPartnerContactType[];
}

export const initialState: State = featureAdapter.getInitialState({
  pagination: null,
  isLoading: false,
  error: null,
  tags: null,
  services: null,
  contactTypes: null,
});
