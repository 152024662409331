import { Action, createReducer, on } from '@ngrx/store';

import * as fromActions from './actions';
import * as fromState from './state';

export const reducer = createReducer(
  fromState.initialState,
  on(fromActions.loadRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.loadSuccess, (state, { apiPartners, pagination }) =>
    fromState.featureAdapter.setAll(apiPartners, {
      ...state,
      pagination,
      isLoading: false,
      error: null,
    }),
  ),
  on(fromActions.loadFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.createApiPartnerRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.createApiPartnerSuccess, (state, { apiPartner }) =>
    fromState.featureAdapter.addOne(apiPartner, {
      ...state,
      isLoading: false,
      error: null,
    }),
  ),
  on(fromActions.createApiPartnerFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.editApiPartnerRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.editApiPartnerSuccess, (state, { apiPartner }) =>
    fromState.featureAdapter.upsertOne(apiPartner, {
      ...state,
      isLoading: false,
      error: null,
    }),
  ),
  on(fromActions.editApiPartnerFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.deleteApiPartnerRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.deleteApiPartnerSuccess, (state, { apiPartnerId }) =>
    fromState.featureAdapter.removeOne(apiPartnerId, {
      ...state,
      isLoading: false,
      error: null,
    }),
  ),
  on(fromActions.deleteApiPartnerFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.loadContactTypesRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.loadContactTypesSuccess, (state, { contactTypes }) => ({
    ...state,
    contactTypes: contactTypes,
    isLoading: false,
    error: null,
  })),
  on(fromActions.loadContactTypesFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.loadTagsRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.loadTagsSuccess, (state, { tags }) => ({
    ...state,
    tags: tags,
    isLoading: false,
    error: null,
  })),
  on(fromActions.loadTagsFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.loadServicesRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.loadServicesSuccess, (state, { services }) => ({
    ...state,
    services: services,
    isLoading: false,
    error: null,
  })),
  on(fromActions.loadServicesFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.loadDetailsRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.loadDetailsSuccess, (state, { apiPartner }) =>
    fromState.featureAdapter.upsertOne(apiPartner, {
      ...state,
      isLoading: false,
      error: null,
    }),
  ),
  on(fromActions.loadDetailsFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.resetState, () => fromState.initialState),
);

export function apiPartnersReducer(
  state: fromState.State | undefined,
  action: Action,
) {
  return reducer(state, action);
}
