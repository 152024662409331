import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ApiPartner } from '../../models';

import { featureAdapter, State } from './state';

export const getError = (state: State) => state.error;

export const getIsLoading = (state: State) => state.isLoading;

export const getPagination = (state: State) => state.pagination;

export const getContactTypes = (state: State) => state.contactTypes;

export const getTags = (state: State) => state.tags;

export const getServices = (state: State) => state.services;

export const selectState = createFeatureSelector<State>('apiPartners');

export const selectAllItems =
  featureAdapter.getSelectors(selectState).selectAll;

export const selectById = (id: number) =>
  createSelector(selectState, (state) => state.entities[id]);

export const selectError = createSelector(selectState, getError);

export const selectIsLoading = createSelector(selectState, getIsLoading);

export const selectPagination = createSelector(selectState, getPagination);

export const selectContactTypes = createSelector(selectState, getContactTypes);

export const selectTags = createSelector(selectState, getTags);

export const selectServices = createSelector(selectState, getServices);
