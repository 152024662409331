import { createAction, props } from '@ngrx/store';

import { IPagination } from '../../core/models/api/pagination/pagination.model';
import {
  ApiPartner,
  ApiPartnerContactType,
  ApiPartnerSearchRequest,
  ApiPartnerService,
  ApiPartnerTag,
} from '../../models';
import { Hooks } from '../../helpers';

export const loadRequest = createAction(
  '[Api Partners] Load Request',
  props<{
    searchParams: ApiPartnerSearchRequest;
  }>(),
);

export const loadSuccess = createAction(
  '[Api Partners] Load Success',
  props<{ apiPartners: Partial<ApiPartner[]>; pagination: IPagination }>(),
);

export const loadFailure = createAction(
  '[Api Partners] Load Failure',
  props<{ error: any }>(),
);

export const loadDetailsRequest = createAction(
  '[Api Partners] Load Details Request',
  props<{
    apiPartnerId: number;
  }>(),
);

export const loadDetailsSuccess = createAction(
  '[Api Partners] Load Details Success',
  props<{ apiPartner: ApiPartner; apiPartnerId: number }>(),
);

export const loadDetailsFailure = createAction(
  '[Api Partners] Load Details Failure',
  props<{ error: any }>(),
);

export const createApiPartnerRequest = createAction(
  '[Api Partners] Create API Partner Request',
  props<{ apiPartner: ApiPartner; hooks?: Hooks }>(),
);

export const createApiPartnerSuccess = createAction(
  '[Api Partners] Create API Partner Success',
  props<{ apiPartner: ApiPartner }>(),
);

export const createApiPartnerFailure = createAction(
  '[Api Partners] Create API Partner Failure',
  props<{ error: any }>(),
);

export const editApiPartnerRequest = createAction(
  '[Api Partners] Edit API Partner Request',
  props<{ apiPartner: ApiPartner }>(),
);

export const editApiPartnerSuccess = createAction(
  '[Api Partners] Edit API Partner Success',
  props<{ apiPartner: ApiPartner }>(),
);

export const editApiPartnerFailure = createAction(
  '[Api Partners] Edit API Partner Failure',
  props<{ error: any }>(),
);

export const deleteApiPartnerRequest = createAction(
  '[Api Partners] Delete API Partner Request',
  props<{ apiPartnerId: number }>(),
);

export const deleteApiPartnerSuccess = createAction(
  '[Api Partners] Delete API Partner Success',
  props<{ apiPartnerId: number }>(),
);

export const deleteApiPartnerFailure = createAction(
  '[Api Partners] Delete API Partner Failure',
  props<{ error: any }>(),
);

export const loadTagsRequest = createAction('[Api Partners] Load Tags Request');

export const loadTagsSuccess = createAction(
  '[Api Partners] Load Tags Success',
  props<{ tags: ApiPartnerTag[] }>(),
);

export const loadTagsFailure = createAction(
  '[Api Partners] Load Tags Failure',
  props<{ error: any }>(),
);

export const loadContactTypesRequest = createAction(
  '[Api Partners] Load ContactTypes Request',
);

export const loadContactTypesSuccess = createAction(
  '[Api Partners] Load ContactTypes Success',
  props<{ contactTypes: ApiPartnerContactType[] }>(),
);

export const loadContactTypesFailure = createAction(
  '[Api Partners] Load ContactTypes Failure',
  props<{ error: any }>(),
);

export const loadServicesRequest = createAction(
  '[Api Partners] Load Services Request',
);

export const loadServicesSuccess = createAction(
  '[Api Partners] Load Services Success',
  props<{ services: ApiPartnerService[] }>(),
);

export const loadServicesFailure = createAction(
  '[Api Partners] Load Services Failure',
  props<{ error: any }>(),
);
export const resetState = createAction('[Api Partners] Reset State');
